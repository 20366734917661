<template>
    <div id="talkjs-container" style="height: 100%">
        <i>Loading chat...</i>
    </div>
</template>

<script>
import Talk from 'talkjs';

export default {
    name: 'Inbox',
    props: {
        currentUser: {
            type: Object,
            required: true,
        },
    },

    mounted: async function () {
        await Talk.ready;
        const me = new Talk.User({
            id: 'tom@arenacx.com',
            name: 'Alice',
            email: 'alice@example.com',
            photoUrl: 'https://images.squarespace-cdn.com/content/v1/5f7b2c5c564e895407c5a851/1629209846157-LLL2IJYJLWWCX7C0N1MR/bo_boyd_headshot.png?format=32w',
            welcomeMessage: 'Hey there! How are you? :-)',
        });
        const session = new Talk.Session({
            appId: 't3Iq5Jul',
            me: me,
            signature: "41b31b12132769810986743ff7c2162876169795f0b00c4285b4a62705d46bbb"
        });
        const other = new Talk.User({
            id: '654321',
            name: 'Alan Pendleton',
            email: 'Alan@arenacx.com',
            photoUrl: 'https://images.squarespace-cdn.com/content/v1/5f7b2c5c564e895407c5a851/1629209621800-7NR6HOUEEIDQG7VHMO1K/alan_pendleton_headshot.png?format=32w',
            welcomeMessage: 'Madison has built a questionnaire for customer service leads that ask the prospect to fill in a lot more information about what they are looking for. ',
        });

        const conversation = session.getOrCreateConversation(
            Talk.oneOnOneId(me, other)
        );
        conversation.setParticipant(me);
        conversation.setParticipant(other);

        const inbox = session.createInbox();
        inbox.select(conversation);
        inbox.mount(document.getElementById('talkjs-container'));
        
    },
};
</script>